<template lang="pug">
.wf-table.full-box
  DataTable(
    ref="dataTable"
    serverSide
    resource="/settlement/list"
    size="medium"
    :defaultSortBy="[{prop: 'settlementTime', order: 'desc'}]"
    :filterFields="filterFields"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList")
  el-dialog.dialog-box(
    v-if="visible"
    title="结算"
    append-to-body
    width="50%"
    :visible.sync="visible"
    :before-close="handleDialogClose")
    .form-box
      .form-item
        .label-box.flex-center
          span.select-label 结算水厂
        .item-box
          dataform-select.full-width(
            :keyName="org"
            v-model="dialogData.orgId"
            :options="waterWorksRelation"
            @change="getSettleMonths")
      .form-item.none-border-top(v-if="dialogData.orgId")
        .label-box.flex-center
          span.select-label 结算月份
        .item-box
          dataform-select.full-width(
            :keyName="month"
            v-model="dialogData.settlementMonth"
            :options="settleMonthRelation"
            @change="getSettleInfo")
      span.error-tip(v-if="dialogData.orgId && dialogData.settlementMonth") 注意：结算后将会冻结本期抄收数据，生成报表和下一期的抄表数据，并且不能修改！
      span.error-tip(v-else) 注意：需先选择结算水厂与结算月份!
      ul.bill-total-info(v-if="dialogData.orgId && dialogData.settlementMonth")
        li(
          v-for="field in totalFields"
          :key="field.name")
          span.total-label {{ `${field.label}:` }}
          span.total-content {{ dialogData[field.name]|renderTotal(field) }}
    ButtonGroup(
      slot="footer"
      :buttonList="dialogButtonList")
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SettleManage',
  filters: {
    renderTotal (val, field) {
      const value = val || 0
      const str = field.toFixed === false ? value : Number(value).toFixed(2)
      const unit = field.unit || '元'
      return str + unit
    }
  },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'settle', url: '/meterRead/settleManage/settle' }
    ])
    return {
      // 表格筛选表单配置
      filterFields: [{
        name: 'settlementTime',
        label: '结算日期',
        form: {
          tag: 'date',
          dateType: 'daterange'
        }
      }],
      // 表格列配置
      columns: [
        { name: 'settlementMonth', label: '结算月份', width: 100 },
        { name: 'shouldReadingCount', label: '应抄户数', minWidth: 100 },
        { name: 'readedCount', label: '实抄户数', minWidth: 100 },
        { name: 'readingNum', label: '实抄水量', minWidth: 100 },
        { name: 'notBillNum', label: '不出账水量', minWidth: 120 },
        { name: 'billAmount', label: '总金额', minWidth: 100 },
        { name: 'waterAmount', label: '水费总额', minWidth: 100 },
        { name: 'sewageAmount', label: '污水费总额', minWidth: 120 },
        { name: 'waterResourceAmount', label: '水资源费总额', minWidth: 120 },
        { name: 'settlementUser', label: '结算人', minWidth: 100 },
        { name: 'orgName', label: '水厂', width: 120 },
        { name: 'settlementTime', label: '结算日期', width: 120, render: { type: 'date' } }
      ],
      // 表格数据
      tableData: [],
      toolbarButtonList: actionRights.settle ? [
        {
          label: '结算',
          name: 'settle',
          type: 'primary',
          func: () => {
            this.visible = true
          }
        }
      ] : [],
      totalFields: [
        { name: 'shouldReadingCount', label: '应抄户数', toFixed: false, unit: '户' },
        { name: 'readedCount', label: '实抄户数', toFixed: false, unit: '户' },
        { name: 'readingNum', label: '实抄水量', toFixed: false, unit: 'm³' },
        { name: 'notBillNum', label: '不出账水量', toFixed: false, unit: 'm³' },
        { name: 'billAmount', label: '总金额' },
        { name: 'waterAmount', label: '水费总额' },
        { name: 'sewageAmount', label: '污水费总额' },
        { name: 'waterResourceAmount', label: '水资源费总额' }
      ],
      // 结算弹窗配置
      visible: false,
      waterWorksRelation: [],
      settleMonthRelation: [],
      dialogData: {
        orgId: null,
        settlementMonth: null
      },
      dialogButtonList: [{
        name: 'confirm',
        label: '结算',
        type: 'primary',
        func: this.handleSettle
      }, {
        name: 'close',
        label: '取消',
        func: this.handleDialogClose
      }]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations', 'getDyncRelation']),
    renderRelation () {
      this.getRelations(['waterWorks'])
        .then(res => {
          this.waterWorksRelation = res.waterWorks
        })
    },
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    getSettleMonths () {
      this.dialogData.settlementMonth = null
      this.settleMonthRelation = []
      return new Promise((resolve, reject) => {
        this.$get({ url: `/settlement/getSettlementMonth?orgId=${this.dialogData.orgId}` })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            const options = !res.data ? [] : res.data.map(m => ({ label: m, value: m }))
            this.settleMonthRelation = options
            this.$set(this.dialogData, 'settlementMonth', options[0] ? options[0].value : null)
            this.getSettleInfo()
            resolve(res.data)
          })
          .catch(reject)
      })
    },
    getSettleInfo () {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/settlement/stat?orgId=${this.dialogData.orgId}&settlementMonth=${this.dialogData.settlementMonth}`,
          params: {
            flag: false
          }
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve(res.data)
            const result = {
              orgId: this.dialogData.orgId,
              settlementMonth: this.dialogData.settlementMonth,
              ...res.data
            }
            this.$set(this, 'dialogData', result)
          })
          .catch(reject)
      })
    },
    handleDialogClose () {
      this.visible = false
      this.dialogData = { orgId: null }
    },
    handleSettle () {
      if (!this.dialogData.orgId || !this.dialogData.settlementMonth) {
        this.$message.warning('请选择结算水厂与结算月份')
        return
      }
      return new Promise((resolve, reject) => {
        this.$post({ url: `/settlement/save?orgId=${this.dialogData.orgId}&settlementMonth=${this.dialogData.settlementMonth}` })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.$message.success('结算成功')
            resolve('success')
            this.handleDialogClose()
            this.getData()
          })
          .catch(reject)
      })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.error-tip
  color: $--color-danger
.bill-total-info
  display: grid
  grid-template-columns: repeat(2, 1fr)
  margin-bottom: 8px
  li
    line-height: 36px
    .total-label
      color: #595959
    .total-content
      margin-left: 8px
      font-weight: 600
.form-item
  display: flex
  border: 1px solid #dfdfdf
  &:last-of-type
    margin-bottom: 8px
  &.none-border-top
    border-top: unset
  .label-box
    padding: 16px 8px
    background-color: #fff
    width: 130px
    text-align: center
    .select-label
      margin-right: 8px
      // font-weight: 600
  .item-box
    background-color: #f7f8fa
    padding: 16px 8px
    width: calc( 100% - 130px )
</style>
